import config from "@/config";

import { get, del, post, patch } from "@/requests"


export async function getDoctorsData() {
  return get(`${config.BACK_IP}/doctor`)
}

export async function getDoctorData(id) {
  return get(`${config.BACK_IP}/doctor/${id}`)
}

export async function getPatientsDoctorData(doctor_id) {
  return get(`${config.BACK_IP}/doctor/${doctor_id}/patients`)
}

export async function createNewDoctor(newDoctor) {
  return post(`${config.BACK_IP}/register`, newDoctor)
}

export async function updateDoctor(newDoctor, id) {
  return patch(`${config.BACK_IP}/doctor/${id}`, newDoctor)
}

export function deleteDoctor(id) {
  return del(`${config.BACK_IP}/doctor/${id}`)
}
export function deletePatientDoctor(id) {
  return del(`${config.BACK_IP}/patient/${id}`)
}