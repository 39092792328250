<template>
    <div>
        <CCard>
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="userFields" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { createNewDoctor } from "@/services/doctors";
import AbstractForm from '@/components/Forms/AbstractForm.vue';
export default {
    name: 'formDoctor',
    components: {
        AbstractForm,
    },
    data(){
        return{
        title: "Añadir un nuevo doctor",
        id: null,
        formContent: {
            buttons: [
                {
                    textButton: 'Volver',
                    color: 'secondary',
                    event: 'back',
                    style: 'margin: 10px',
                    active: true
                },
                {
                    textButton: 'Crear',
                    color: 'success',
                    event: 'add',
                    style: 'margin: 10px',
                    active: this.$store.state.isAdmin
                },
            ],
            rows: [
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'name',
                                label: 'Nombre',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'surname',
                                label: 'Apellidos',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'username',
                                label: 'Nombre de usuario',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'password',
                                value: '',
                                key: 'password',
                                label: 'Contraseña',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'password',
                                value: '',
                                key: 'repeatPassword',
                                label: 'Confirmar contraseña',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'email',
                                label: 'Email',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'date',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'date',
                                value: '',
                                key: 'birthdate',
                                label: 'Fecha de nacimiento',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'select',
                                value: '',
                                key: 'gender',
                                label: 'Género',
                                maxLength: 30,
                                options:
                                    [
                                        { name: 'Hombre', value: "male" },
                                        { name: 'Mujer', value: "female" },
                                        { name: 'Otros', value: "other" }
                                    ],
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'textArea',
                                value: '',
                                key: 'description',
                                label: 'Descripcion',
                                maxLength: 1000,
                            },
                        },
                    ]
                },
                {
                    style: [],
                    class: [],
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'weight',
                                label: 'Peso',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'height',
                                label: 'Altura',
                                maxLength: 30,
                            },
                        },
                    ]
                },
            ],
        },
        userFields: {
            username: null,
            name: null,
            password: null,
            repeatPassword: null,
            surname: null,
            email: null,
            birthdate: null,
            gender: null,
            description: null,
            weight: null,
            height: null
        }
     }
    },
    created() {},
    methods: {
        onEmitMethods(event, dataDoctor) {
            switch (event) {
                case 'add': this.addDoctor(dataDoctor); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        checkInputs(dataDoctor) {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const numericRegex = /^[0-9]+$/; // Expresión regular para validar números enteros
            const validationRules = [
                { field: 'name', message: 'Introduzca el nombre (máximo de 30 caracteres).', maxLength: 30 },
                { field: 'surname', message: 'Introduzca el apellid (máximo de 20 caracteres).', maxLength: 20 },
                { field: 'username', message: 'Introduzca el nombre de usuario (máximo de 30 caracteres).', maxLength: 30 },
                { field: 'username', message: 'El nombre de usuario no debe contener espacios', noSpaces: true },
                { field: 'password', message: 'La contraseña debe tener mínimo 8 caracteres (máximo de 30 caracteres).', minLength: 8, maxLength: 30 },
                { field: 'repeatPassword', message: 'Las contraseñas deben ser iguales.', equalField: 'password' },
                { field: 'email', message: 'Introduzca un email válido.', regex: emailRegex },
                { field: 'birthdate', message: 'Introduzca la fecha de nacimiento.' },
                { field: 'gender', message: 'Seleccione un género.', value: '' },
                { field: 'description', message: 'Introduzca una descripción (máximo de 1000 caracteres).', maxLength: 1000 },
                { field: 'weight', message: 'Introduzca el peso en kilogramos (sólo valores enteros).', regex: numericRegex, minValue: 0 },
                { field: 'height', message: 'Introduzca la altura en centímetros (sólo valores enteros).', regex: numericRegex, minValue: 0 },
            ];
            for (const rule of validationRules) {
                const value = dataDoctor[rule.field];
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataDoctor[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        addDoctor(dataDoctor) {
            if (this.checkInputs(dataDoctor) == false) {
                return;
            }
            createNewDoctor(dataDoctor)
                .then(() => {
                    this.$router.push("/doctor")
                })
                .catch((error) => { 
                    this.$store.state.errorAlert = { status: true, msg: error.toString() } 
                })
        },
        /**
         * Este método se ejecuta al pulsar Ver información, para poder ver información del paciente.
         */
        handleSeeInfo(item) {
            this.$router.push(`/patient/info/${item.patient_id}`);
        },
        /**
         * Función para volver atrás
         */
        back() {
            this.$router.push("/doctor");
        }
    }
}
</script>

<style scoped>
.table-wrapper-title {
    padding: 10px 25px;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 20px;
}

@media (max-width: 768px) {
    .table-wrapper .card-body {
        padding: 0;
    }

    .table-wrapper-title {
        padding: 10px;
        margin-bottom: 0;
        margin-top: 0px;
        font-size: 18px;
    }
}
</style>